/**
 * 组合规则校验，返回err
 * @param {any} value 需要校验的值
 * @param {rule} rules 规则组合
 */
const validate = function(value, ...rules) {
	let err = null;
	for (let i = 0; i < rules.length; i++) {
		if (!rules[i].pattern.test(value)) {
			err = new Error(rules[i].message);
			break;
		}
	}
	return err;
}

const rules = {
	required: {
		required: true,
		message: '该项为必填项'
	},
	float: {
		pattern: /^(-?\d+)(\.\d+)?$/,
		message: '请输入正确的数字'
	},
	float2: {
		pattern: /^((0{1}\.\d{1,2})|([1-9]\d*\.{1}\d{1,2})|([1-9]+\d*)|0)$/,
		message: '小数点后保留两位小数'
	},
	int: {
		pattern: /^(-?\d+)[0-9]*$/,
		message: '请输入整数'
	},
	pInt: {
		pattern: /^\d+$/,
		message: '请输入正整数'
	},
	nInt: {
		pattern: /^((-\d+)|(0+))$/,
		message: '请输入负整数'
	},
	notZero: {
		validator(rule, value, callback) {
			let err = null;
			if (Number(value) == 0) {
				err = new Error('该项不能为0');
			}
			err ? callback(err) : callback();
		}
	},
	// 身份证校验
	idcard: {
		pattern: /(^\d{15}$)|(^\d{17}([0-9]|X)$)/,
		message: '请输入正确的身份证号码'
	},
	phone: {
		pattern: /^1\d{10}$/,
		message: '请输入正确的手机号码'
	},
	telephone: {
		pattern: /^\d+$/,
		message: '请输入正确的电话号码'
	},
	price: {
		validator(rule, value, callback) {
			let err = validate(value, rules.float, rules.float2);
			err ? callback(err) : callback();
		}
	},
	notSpecial: {
		pattern: /^[A-Za-z0-9\u4e00-\u9fa5]+$/,
		message: '不允许输入空格等特殊符号'
	},
	username: {
		pattern: /^[A-Za-z0-9]+$/,
		message: '只允许输入字母和数字'
	},
	email: {
		pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
		message: '请输入正确的邮箱'
	},
	bankNumber: {
		pattern: /^([0-9]|-)*$/,
		message: '请输入正确的银行卡号'
	},
	notEmptyArray: {
		type: 'array',
		required: true,
		message: '请至少选择一个选项',
		trigger: 'change'
	}

}

export default rules
